import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../../templates/LayoutService'
import { blogHeader, blogPostContainer, postContentBlock } from '../../styles/components/blog.module.scss'
import { pageContentMedia } from '../../styles/components/blocks.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../../styles/components/boxes.module.scss'
import '../../styles/components/content.module.scss'
import ServicesList from '../../components/blocks/ServicesList'
import { Link } from 'gatsby'

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Is It Too Cold for a Charter Boat Party? | Tampa Bay Boating Adventures</title>
      </Helmet>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Guy chilling on a boat with hot girls in Tampa Bay, FL."
                  src="../../images/blog/guy-chilling-on-boat-with-hot-girls.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className='row center'>
                <div className='col'>
                  <h1>Is It Too Cold for a Charter Boat Party?</h1>
                  <p>December 10, 2022</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xxs-12'>
                  <div className={postContentBlock}>
                    <h2>When Is It Too Cold for a Charter Boat Party?</h2>
                    <h3>The Tampa Bay area continues to have tropical and comfortable temperatures well into the winter months. It's still a great time to book a charter boat party.</h3>
                    <p>Chartering a boat is a popular activity in the Tampa Bay region – hire the boat for a day, an afternoon or at sunset and enjoy a party cruise like no other. One of the questions that we commonly get asked is, ‘Is it too cold for a charter boat party?’. So, let’s take a closer look:</p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="Young man with a drink looking out over Tampa Bay, FL."
                          src="../../images/blog/young-man-with-drink-looking-out-over-tampa-bay.jpg"
                        />
                      </div>
                    </div>
                    <h2>Boat parties continue year-round in Tampa Bay - Just adjust your clothing!</h2>
                    <p><strong>November is a comfortable temperature for chartering a boat around Tampa Bay – with highs of 86 degrees Fahrenheit, and lows of around 65.</strong> This is a comfortable temperature for heading out on the boat – you can still wear your swimming gear and have some fun in the sun. For those who get cold easier, a light jacket can be worn but this won’t stop you from having a great boat party!</p>
                    <p>The coolest months in Tampa Bay are December, January and February – to help you out, take a look at the highest and lowest average temperatures below:</p>
                    <p>During the cooler months in the table above, it might not be warm enough to wear your swimsuit all day on the boat. A light jacket, however, is ideal, and still allows you to explore the beautiful area. We offer a number of different charter lengths – from 4 to 6 to 8 hours and work with you to create a fun charter experience. <strong>One of our most popular charters at this time of year is the sunset boat charter which allows you to see the beautiful sunset over the bay.</strong></p>
                    <hr />
                    <h2>What does a charter boat party look like in the colder months?</h2>
                    <p>Even in these cooler months, however, there are still boat charter options to get you out on the water and enjoying your downtime. <strong>Remember, chartering a boat doesn’t mean that you need to strip down to a bikini or swimming shorts and jump into the water – there are other ways to experience a boat charter when it turns cooler.</strong> For example, why not select to cruise up the Riverwalk in Downtown Tampa, where you can stop and dock at a number of different areas in the Tampa region. For some excellent food, <strong>don’t miss our Ricks on the Water stop where you can find a great atmosphere and a bustling bar</strong> where you can find delicious food at an affordable cost. Continue on the boat down the Riverwalk and discover the islands along the Hillsborough River such as Pine Key Island. Also known as Beer Can Island, this highlight is only accessible by boat and has a reputation for being a top-class party island – a destination not to be missed! Other highlights on this boat trip include the marketplace food hall at Armature Works and Curtis Hixon Park.</p>
                    <hr />
                    <h2>Let’s keep the boat party going throughout the fall and winter!</h2>
                    <p>So what are you waiting for? In November, the weather is cooler but still great for heading out on a charter boat. During the cooler months, there are a number of alternative options for your charter boat that don’t include spending time in the colder water. Here at Tampa Bay Boating Adventures, we can help find the trip that is right for you, allowing you to charter a boat all year round. Our friendly staff are here to help and can’t wait to hear from you. Give us a call at <a href="tel:813-742-8178">(813) 742-8178</a> to book an upcoming charter.</p>
                    <hr />
                    <h2>Select Your Party Boat Charter</h2>
                    <h3>See what all's included with our wide variety of party boat charter packages.</h3>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default BlogPage